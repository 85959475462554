import { createSlice } from "@reduxjs/toolkit";

if (localStorage.getItem("userRole") === "client") {
  ["token", "vendorId", "branchId", "userRole", "userData"].forEach(
    (element) => {
      localStorage.removeItem(element);
    }
  );
}

// check for the existance of the userData object as the whole site depends on it for now
const getUserData = () => {
  let userData;
  try {
    userData = JSON.parse(localStorage.getItem("userData"));
  } catch {
    localStorage.removeItem("userData");
  }

  return userData;
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: localStorage.getItem("token"),
    vendorId: localStorage.getItem("vendorId"),
    branchId: localStorage.getItem("branchId"),
    userRole: localStorage.getItem("userRole"),
    userData: getUserData(),
  },
  reducers: {
    login(state, action) {
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("vendorId", action.payload.vendorId);
      localStorage.setItem("branchId", action.payload.branchId);
      localStorage.setItem("userRole", action.payload.userRole);
      localStorage.setItem("userData", JSON.stringify(action.payload.userData));

      state.token = localStorage.getItem("token");
      state.vendorId = localStorage.getItem("vendorId");
      state.branchId = localStorage.getItem("branchId");
      state.userRole = localStorage.getItem("userRole");
      state.userData = getUserData();
      return state;
    },

    logout(state) {
      state.token = localStorage.getItem("token");
      state.vendorId = localStorage.getItem("vendorId");
      state.branchId = localStorage.getItem("branchId");
      state.userRole = localStorage.getItem("userRole");
      state.userData = getUserData();
      return state;
    },

    update(state, { payload: { userData } }) {
      const userDataObj = JSON.stringify(userData);
      localStorage.setItem("userData", userDataObj);
      state.userData = getUserData();
      return state;
    },
  },
});

export const authActions = authSlice.actions;
export const { update: setUpdateProfile } = authSlice.actions;
export const selectAuth = (state) => state.auth;

export default authSlice.reducer;
