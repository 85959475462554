import { emailSchema, passwordSchema } from "helpers/validations/common";
import Joi from "joi";

export const loginSchema = Joi.object({
  email: emailSchema,
  password: passwordSchema,
});

export const loginFormData = [
  { name: "email", type: "text", required: true },
  { name: "password", type: "password", required: true },
];
