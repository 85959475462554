import Axios, { guestAxios } from "./Axios";

let branchServices = {
  login: async (obj) => {
    const response = await guestAxios.post("/branch/login", obj);
    return response;
  },
  updateInfo: async (branchId, data) => {
    const response = await Axios.put(`/branch/update?branch=${branchId}`, data);
    return response;
  },
  listAllBranchProductsOfType: async (branchId = "", isHotDeal = false) => {
    const response = await Axios.get(
      `/branch/product/list?branches._id=${branchId}&isHotDeal=${isHotDeal}`
    );
    return response;
  },

  listAllProducts: async (branchId = "") => {
    const response = await Axios.get(`/branch/product/list?branch=${branchId}`);
    return response;
  },

  getCashier: async (_id) => {
    const response = await Axios.get(`branch/cashier/get`, { params: { _id } });
    return response.data;
  },

  addCashier: async (obj) => {
    const response = await Axios.post(`branch/cashier/create`, obj);
    return response;
  },
  updateCashier: async ({ _id, ...obj }) => {
    const response = await Axios.put(`branch/cashier/update`, obj, {
      params: { _id },
    });
    return response.data;
  },
  resetCashierPassword: async (obj) => {
    const response = await Axios.put(`branch/cashier/resetPassword`, obj);
    return response;
  },
  deleteCashier: async (_id) => {
    const response = await Axios.delete(`branch/cashier/remove`, {
      params: { _id },
    });
    return response.data;
  },
  createOffer: async (obj) => {
    const response = await Axios.post(`/branch/product/create`, obj);
    return response;
  },

  getBranchDetails: async (branchId, vendorId) => {
    const response = await Axios.get(`/branch/get?branch=${branchId}`);
    return response;
  },

  getOfferDetails: async (branchId, offerId) => {
    const response = await Axios.get(
      `/branch/product/get?branche=${branchId}&_id=${offerId}`
    );
    return response;
  },

  getAccountDetails: async (branchId) => {
    const response = await Axios.get(`/branch/product/get?branch=${branchId}`);
    return response;
  },

  editOfferDetails: async (offerId, branchId, obj) => {
    const response = await Axios.put(
      `/branch/product/update?_id=${offerId}&branches._id=${branchId}`,
      obj
    );
    return response;
  },

  deleteOffer: async (offerId, branchId) => {
    const response = await Axios.delete(
      `branch/product/remove?_id=${offerId}&branches._id=${branchId}`
    );
    return response;
  },

  addOfferImg: async (id, obj) => {
    const response = await Axios.post(`/branch/product/image?_id=${id}`, obj);
    return response;
  },

  listAllCategories: async () => {
    const response = await Axios.get(`/branch/category/list`);
    return response;
  },
  listAllProductCategories: async () => {
    const response = await Axios.get(`/branch/category/list?type=product`);
    return response;
  },
  listAllVendorCategories: async () => {
    const response = await Axios.get(`/branch/category/list?type=vendor`);
    return response;
  },

  searchOffersDeals: async (params) => {
    const response = await Axios.get(`/branch/product/list`, {
      params,
    });
    return response;
  },

  createAd: async (obj) => {
    const response = await Axios.post(`/branch/ad/create`, obj);
    return response;
  },
  uploadAdImg: async (id, obj) => {
    const response = await Axios.post(`/branch/ad/image?_id=${id}`, obj);
    return response;
  },

  listAllAds: async () => (await Axios.get(`/branch/ad/list`)).data,

  getClientFromBarcode: async (barcode) =>
    (
      await Axios.get("branch/client/get", {
        params: { barcode },
      })
    )?.data,

  applyCoupon: async (cart, coupon) =>
    (
      await Axios.post(`branch/coupon/apply?cart=${cart}&coupon=${coupon}`, {
        // params: { cart, coupon },
      })
    )?.data,

  rejectCoupon: async (cart) =>
    (
      await Axios.delete("branch/coupon/remove", {
        params: { cart },
      })
    )?.data,

  useVendorPoints: async (pointsToRemove, params) =>
    (
      await Axios.put(
        "branch/points/useVendorPoints",
        { pointsToRemove },
        { params }
      )
    )?.data,

  useSystemPoints: async (vipPoints, params) =>
    (
      await Axios.put(
        "branch/points/useSystemPoints",
        { vipPoints },
        { params }
      )
    )?.data,

  checkout: async (obj) =>
    (await Axios.post("branch/order/checkout", obj))?.data,

  useFreeTrial: async (obj) =>
    (await Axios.post("branch/order/freetrial", obj))?.data,
};

export default branchServices;
