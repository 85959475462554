import schema from "../helpers/schema";
import Axios, { guestAxios } from "./Axios";
import { adsServices } from "./modules/ads";

const vendorServices = {
  login: async (obj) => {
    const response = await guestAxios.post("/vendor/login", obj);
    return response;
  },

  register: async (obj) => {
    const response = await guestAxios.post("/vendor/join/create", obj);
    return response;
  },

  updateInfo: async (vendorId, data) => {
    const response = await Axios.put(`/vendor/update?vendor=${vendorId}`, data);
    return response;
  },
  uploadVendorImg: async (obj) => {
    const response = await Axios.post(`/vendor/image?`, obj);
    return response;
  },

  listBranches: async (id = "", page = "1", limit = 8) => {
    const response = await Axios.get(
      `/vendor/branch/list?vendor=${id}&page=${page}&limit=${limit}`
    );
    return response;
  },

  listAllBranches: async (id = "") => {
    const response = await Axios.get(`/vendor/branch/list?vendor=${id}`, {
      params: { isActive: true },
    });
    return response;
  },

  listAllCategories: async () => {
    const response = await Axios.get(`/vendor/category/list`);
    return response;
  },

  listAllProductCategories: async () => {
    const response = await Axios.get(`/vendor/category/list?type=product`);
    return response;
  },

  listAllVendorCategories: async () => {
    const response = await Axios.get(`/vendor/category/list?type=vendor`);
    return response;
  },

  listAllProducts: async (vendorId = "") => {
    const response = await Axios.get(
      `/vendor/product/list?vendor=${vendorId}`,
      { params: { isActive: true } }
    );
    return response;
  },

  listAllBranchProductsOfType: async (vendor, branch, isHotDeal = false) => {
    const response = await Axios.get(
      `/vendor/product/list?isHotDeal=${isHotDeal}`,
      { params: { vendor, branch, isActive: true } }
    );
    return response;
  },

  listAllVendorProductsOfType: async (vendorId = "", isHotDeal = false) => {
    const response = await Axios.get(
      `/vendor/product/list?vendor=${vendorId}&isHotDeal=${isHotDeal}`,
      { params: { isActive: true } }
    );
    return response;
  },

  createBranch: async (obj) => {
    const response = await Axios.post(`/vendor/branch/create`, obj);
    return response;
  },

  addBranchImg: async (id, obj) => {
    const response = await Axios.post(`/vendor/branch/image?_id=${id}`, obj, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  },

  addBranchCover: async (id, obj) => {
    const response = await Axios.post(`/vendor/branch/cover?_id=${id}`, obj, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response;
  },

  getBranchDetails: async (branchId, vendorId) => {
    const response = await Axios.get(
      `vendor/branch/get?_id=${branchId}&vendor=${vendorId}`
    );
    return response;
  },

  deleteBranch: async (branchId, vendorId) => {
    const response = await Axios.delete(
      `vendor/branch/remove?_id=${branchId}&vendor=${vendorId}`
    );
    return response;
  },

  getOfferDetails: async (branchId, vendorId, offerId) => {
    const response = await Axios.get(
      `/vendor/product/get?branch=${branchId}&vendor=${vendorId}&_id=${offerId}`
    );
    return response;
  },

  getAccountDetails: async (vendorId) => {
    const response = await Axios.get(`/vendor/get?_id=${vendorId}`);
    return response;
  },

  editOfferDetails: async (offerId, branchId, vendorId, obj) => {
    const response = await Axios.put(
      `/vendor/product/update?_id=${offerId}&branch=${branchId}&vendor=${vendorId}`,
      obj
    );
    return response;
  },

  deleteOffer: async (offerId, vendorId, branchId) => {
    const response = await Axios.delete(`vendor/product/remove`, {
      params: { _id: offerId, vendor: vendorId, branch: branchId },
    });
    return response;
  },

  editBranchDetails: async (branchId, vendorId, obj) => {
    const response = await Axios.put(
      `vendor/branch/update?_id=${branchId}&vendor=${vendorId}`,
      obj
    );
    return response;
  },
  resetBranchPassword: async (obj) => {
    const response = await Axios.put(`vendor/branch/resetPassword`, obj);
    return response;
  },

  createOffer: async (obj) => {
    try {
      const value = await schema.createProduct.validateAsync(obj);
    } catch (err) {
      throw new Error(err.message);
    }

    const response = await Axios.post(`/vendor/product/create`, obj);
    localStorage.removeItem("offerBranch");
    return response;
  },

  addOfferImg: async (id, obj) => {
    const response = await Axios.post(`/vendor/product/image?_id=${id}`, obj);
    return response;
  },
  removeOfferImage: async (id, obj) => {
    const response = await Axios.post(`/vendor/product/removeImage?_id=${id}`, {
      ...obj,
    });
    return response;
  },
  searchBranches: async (params) => {
    const response = await Axios.get(`/vendor/branch/list`, {
      params: { ...params, isActive: true },
    });
    return response.data;
  },
  searchOffersDeals: async (params) => {
    const response = await Axios.get(`/vendor/product/list`, {
      params: { ...params, isActive: true },
    });
    return response?.data;
  },

  addCashier: async (obj) => {
    const response = await Axios.post(`vendor/cashier/create`, obj);
    return response;
  },
  updateCashier: async ({ _id, ...obj }) => {
    const response = await Axios.put(`vendor/cashier/update`, obj, {
      params: { _id },
    });
    return response.data;
  },
  deleteCashier: async (_id) => {
    const response = await Axios.delete(`vendor/cashier/remove`, {
      params: { _id },
    });
    return response.data;
  },

  getCashier: async (_id) => {
    const response = await Axios.get(`vendor/cashier/get`, { params: { _id } });
    return response.data;
  },

  resetCashierPassword: async (obj) => {
    const response = await Axios.put(`vendor/cashier/resetPassword`, obj);
    return response;
  },

  requestCategory: async (obj) => {
    const response = await Axios.post("vendor/category/request", obj);
    return response;
  },
  ...adsServices,

  getClientFromBarcode: async (barcode) =>
    (
      await Axios.get("vendor/client/get", {
        params: { barcode },
      })
    )?.data,

  applyCoupon: async (cart, coupon) =>
    (
      await Axios.post(`vendor/coupon/apply?cart=${cart}&coupon=${coupon}`, {
        // params: { cart, coupon },
      })
    )?.data,

  rejectCoupon: async (cart) =>
    (
      await Axios.delete("vendor/coupon/remove", {
        params: { cart },
      })
    )?.data,

  useVendorPoints: async (pointsToRemove, params) =>
    (
      await Axios.put(
        "vendor/points/useVendorPoints",
        { pointsToRemove },
        { params }
      )
    )?.data,

  useSystemPoints: async (vipPoints, params) =>
    (
      await Axios.put(
        "vendor/points/useSystemPoints",
        { vipPoints },
        { params }
      )
    )?.data,

  checkout: async (obj) =>
    (await Axios.post("vendor/order/checkout", obj))?.data,

  useFreeTrial: async (obj) =>
    (await Axios.post("vendor/order/freetrial", obj))?.data,
};

export default Object.freeze(vendorServices);
