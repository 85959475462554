import { t } from "i18next";
import Joi from "joi";

export const emailSchema = Joi.string()
  .trim()
  .email({ tlds: { allow: false } })
  .normalize()
  .lowercase()
  .messages({
    "string.empty": t("emailValidation"),
    "string.email": t("emailValidation"),
  });

export const passwordSchema = Joi.string()
  .trim()
  .required()
  .messages({
    "string.empty": t("passwordValidation"),
    "any.required": t("passwordValidation"),
    "string.pattern.base": t("passwordValidation"),
  });

export const phoneSchema = Joi.string()
  .trim()
  .pattern(/^\d{11}$/)
  .messages({
    "string.pattern.base": t("phoneValidation"),
    "any.empty": t("phoneValidation"),
  });

export const countrySchema = Joi.any()
  .required()
  .messages({
    "any.required": t("countryValidation"),
  });

export const citySchema = Joi.any()
  .required()
  .messages({
    "any.required": t("cityValidation"),
  });
