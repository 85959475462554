import { MainButton } from "components/Buttons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

export default function RegisterHome() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <MainButton
        text={t("CreateAccount")}
        type="button"
        onClick={() => navigate("create")}
      />
    </>
  );
}
