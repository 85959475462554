import { t } from "i18next";
import i18n from "../locales/i18n";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();
const lang = () => i18n.language;
const toastConfig = () => ({
  position:
    lang() === "ar" ? toast.POSITION.TOP_RIGHT : toast.POSITION.TOP_LEFT,
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
});

const toastPopup = {
  error: (text) => {
    toast.error(t(text), toastConfig());
  },

  success: (text) => {
    toast.success(t(text), toastConfig());
  },
};

export const responseErrorToast = (e) =>
  toastPopup.error(e?.response?.data?.error ?? "somethingWentWrong");

export default toastPopup;
