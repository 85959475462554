import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth-slice";
import notificationReducer from "./notification-slice";

const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",

  reducer: {
    auth: authReducer,
    notification: notificationReducer,
  },
});

export default store;
