import axios from "axios";
import dayjs from "dayjs";
import jwt_decode from "jwt-decode";
import store from "../store";
import { authActions } from "../store/auth-slice";
import endPoint from "./endPoint";
import i18n from "locales/i18n";

const baseURL = endPoint;

axios.defaults.baseURL = endPoint;
axios.defaults.headers["x-app-token"] = "VIP-Team";
axios.defaults.headers["accept-language"] = i18n.language;

const AuthAxios = axios.create();
const Axios = axios.create({ baseURL });

AuthAxios.interceptors.request.use(async (req) => {
  let auth = store.getState().auth;

  const token = jwt_decode(auth.token);

  const isTokenExpired = dayjs.unix(token.exp).diff(dayjs()) <= 1;

  if (!isTokenExpired) {
    req.headers.Authorization = `Bearer ${auth.token}`;
    if (req.method === "get") {
      const skipped = ["subs", "ad/list", "job/get", "ad/get"];
      let isActive = null;

      for (const url of skipped) {
        if (req.url.includes(url)) {
          isActive = null;
          break;
        }
        isActive = true;
      }
      req.params = {
        ...req.params,
        isActive,
      };
    }
    return req;
  }

  if (isTokenExpired) {
    localStorage.removeItem("token");
    store.dispatch(authActions.logout());

    return Promise.reject("you are unautherised");
  }
});
export const guestAxios = Axios;
export default AuthAxios;
