import jwtDecode from "jwt-decode";
import store from "store";
import Axios, { guestAxios } from "./Axios";
import branchServices from "./branchServices";
import vendorServices from "./vendorServices";
import { getLocalStorageKey } from "helpers/local-storage";

const services = {
  vendor: vendorServices,
  branch: branchServices,
};

//TODO: this large obj function should be splitted
export const roleService = (SelectedRole) => {
  const role = SelectedRole ?? store.getState().auth.userRole;
  return {
    login: async (obj) => {
      const { data } = await guestAxios.post(`${role}/login`, obj);
      return data;
    },
    uploadMyImg: async (obj) => {
      const response = await Axios.post(`/${role}/image?`, obj);
      return response;
    },

    getRecoveryCode: async (email) => {
      const { data } = await guestAxios.post(`${role}/recovery`, { email });
      return data;
    },

    resetPassword: async (obj, token) => {
      const { data } = await guestAxios.put(`${role}/resetPassword`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    },
    resetMyPassword: async (obj, token) => {
      const { data } = await Axios.put(`${role}/resetPassword`, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    },
    searchBranches: async (params) => {
      const response = await Axios.get(`/${role}/branch/list`, {
        params: { ...params, isActive: true },
      });
      return response.data;
    },

    updateMyInfo: async (data) => {
      const token = getLocalStorageKey("token");
      if (!token) throw new Error("You are not logged in");
      const decodedToken = jwtDecode(token); // this to handle the absence of userData Object

      const response = await Axios.put(
        `/${role}/update?${[role]}=${decodedToken._id}`,
        data
      );
      return response;
    },

    updateMyCover: async (formData) => {
      const { data } = await Axios.post(`${role}/cover`, formData, {
        params: { _id: store.getState().auth.userData._id },
      });
      return data;
    },

    listAllAds: async (params) => {
      const response = await Axios.get(`/${role}/ad/list`, {
        params,
      });
      return response;
    },

    listAllProducts: async (params) => {
      const response = await Axios.get(`/${role}/product/list`, {
        params,
      });
      return response;
    },

    listAllBranchProductsOfType: async (vendor, branch, isHotDeal = false) => {
      const response = await Axios.get(
        `/${role}/product/list?isHotDeal=${isHotDeal}`,
        { params: { vendor, branches: branch, isActive: true } }
      );
      return response;
    },
    listAllProductCategories: async () => {
      const response = await Axios.get(`/${role}/category/list?type=product`);
      return response;
    },
    listCashier: async (params) => {
      const response = await Axios.get(`/${role}/cashier/list`, { params });
      return response.data;
    },
    listAllVendorCategories: async () => {
      const response = await Axios.get(`/${role}/category/list?type=vendor`);
      return response;
    },
    //
    listAllJobs: async (params) => {
      const response = await Axios.get(`/${role}/job/list`, {
        params,
      });
      return response.data;
    },
    getJobDetails: async (_id) => {
      const response = await Axios.get(`/${role}/job/get`, { params: { _id } });
      return response.data;
    },
    getAdDetails: async (_id) => {
      const token = getLocalStorageKey("token");
      if (!token) throw new Error("You are not logged in");
      const decodedToken = jwtDecode(token); // this to handle the absence of userData Object

      const response = await Axios.get(`/${role}/ad/get`, {
        params: { _id, sponsor: decodedToken._id },
      });
      return response.data;
    },
    createAd: async (obj) => {
      const response = await Axios.post(`/${role}/ad/create`, obj);
      return response;
    },

    updateAd: async (obj, _id) => {
      const response = await Axios.put(
        `/${role}/ad/update`,
        { ...obj },
        {
          params: { _id },
        }
      );
      return response.data;
    },

    createJob: async (obj) => {
      const response = await Axios.post(`/${role}/job/create`, obj);
      return response.data;
    },
    updateJob: async (obj, _id) => {
      const response = await Axios.put(
        `/${role}/job/update`,
        { ...obj },
        {
          params: { _id },
        }
      );
      return response.data;
    },
    removeJob: async (_id) => {
      const response = await Axios.delete(`/${role}/job/remove`, {
        params: { _id },
      });
      return response.data;
    },
    removeAd: async (_id) => {
      const response = await Axios.delete(`/${role}/ad/remove`, {
        params: { _id },
      });
      return response.data;
    },

    //
    listAllPosts: async (params) => {
      const response = await Axios.get(`/${role}/post/list`, {
        params,
      });
      return response.data;
    },
    getPostDetails: async (_id) => {
      const response = await Axios.get(`/${role}/post/get`, {
        params: { _id },
      });
      return response.data;
    },
    createPost: async (obj) => {
      const response = await Axios.post(`/${role}/post/create`, obj);
      return response.data;
    },
    removePost: async (_id) => {
      const response = await Axios.delete(`/${role}/post/remove`, {
        params: { _id },
      });
      return response.data;
    },

    listAllCategories: async (params) => {
      const response = await Axios.get(`/${role}/category/list`, { params });
      return response.data;
    },

    listAllPages: async () => {
      const response = await Axios.get(`/${role}/page/list`);
      return response?.data?.records ?? response?.data?.record;
    },
    listAllSettings: async () => {
      const response = await Axios.get(`/${role}/setting/get`);
      return response?.data?.records ?? response?.data?.record;
    },

    listAllOrderRequests: async (params) => {
      const token = getLocalStorageKey("token");
      if (!token) throw new Error("You are not logged in");
      const decodedToken = jwtDecode(token); // this to handle the absence of userData Object

      const response = await Axios.get(`/${role}/orderRequest/get`, {
        params: { [role]: decodedToken._id, ...params },
      });

      return response.data;
    },

    listAllOrders: async (params) => {
      const token = getLocalStorageKey("token");
      if (!token) throw new Error("You are not logged in");
      const decodedToken = jwtDecode(token); // this to handle the absence of userData Object
      const response = await Axios.get(`/${role}/order/list`, {
        params: { [role]: decodedToken._id, ...params },
      });
      return response?.data;
    },

    updateOrderRequest: async (obj) => {
      const { status, rejectionReasons, shippingFees, total, ...rest } = obj;

      const response = await Axios.put(
        `/${role}/orderRequest/update`,
        {
          status,
          rejectionReasons,
          shippingFees,
          total: +total + +shippingFees,
        },
        {
          params: {
            ...rest,
          },
        }
      );
      return response?.data?.records ?? response?.data?.record;
    },

    updateOrder: async (id, obj) => {
      const response = await Axios.put(
        `/${role}/order/update`,
        {
          ...obj,
        },
        {
          params: {
            _id: id,
          },
        }
      );
      return response?.data?.records ?? response?.data?.record;
    },

    // PRODUCTS
    addProductImage: async (_id, formData) => {
      const response = await Axios.post(`/${role}/product/addImage`, formData, {
        params: { _id },
      });
      return response;
    },

    createOffer: async (obj) => {
      const response = await Axios.post(`/${role}/product/create`, obj);
      return response;
    },

    getPage: async (_id) => {
      const response = await Axios.get(`/${role}/page/get`, {
        params: { _id },
      });
      return response?.data?.records?.[0] ?? response?.data?.record[0];
    },
  };
};
export default services;
