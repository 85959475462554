import Axios from "../Axios";

export const adsServices = {
  createAd: async (obj) => {
    const response = await Axios.post(`/vendor/ad/create`, obj);
    return response;
  },
  uploadAdImg: async (id, obj) => {
    const response = await Axios.post(`/vendor/ad/image?_id=${id}`, obj);
    return response;
  },

  listAllAds: async () => (await Axios.get(`/vendor/ad/list`)).data,
};
