import { lazy, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { roleService } from "services";
import { EVENTS } from "services/socket/config";
import { useNotificationSocket } from "services/socket/notification";
import { useSocket } from "services/socket/provider";
import { setNotifications, updateProfile } from "store/actions";
import { selectAuth } from "store/auth-slice";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const VendorLayout = lazy(() => import("layouts/VendorLayout/VendorLayout"));
const BranchLayout = lazy(() => import("layouts/BranchLayout/BranchLayout"));
const CashierLayout = lazy(() => import("layouts/CashierLayout/CashierLayout"));

export default function ProtectedRoute() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useSelector(selectAuth);
  const { socket, isConnected } = useSocket();
  const { listNotification, listenToNotification } = useNotificationSocket();

  const handleResetAll = () => {
    localStorage.clear();
    navigate("/login");
  };
  async function getLatestProfile() {
    try {
      const myData = await roleService()
        .updateMyInfo({})
        .then((res) => res.data.record);
      updateProfile({ userData: myData });
    } catch {}
  }

  useEffect(() => {
    getLatestProfile();

    if (!auth.token || !isConnected) return;

    listenToNotification((first) => {});

    listNotification();

    socket.on(EVENTS.NOTIFICATION.LIST, (response) => {
      setNotifications(response);
    });

    socket.on(EVENTS.CHAT.CREATE, (res) => {
      if (!res.success) {
        return;
      }

      const room = res.record;
      navigate("/chat", { state: { room } });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.token, isConnected, socket]);

  if (auth.token) {
    switch (auth.userRole) {
      case "vendor":
        return <VendorLayout />;

      case "branch":
        return <BranchLayout />;

      case "cashier":
        return <CashierLayout />;

      default:
        return (
          <div className="flex justify-center items-center h-screen w-full flex-col gap-5 text-xl">
            <>{t("somethingWentWrong")}</>{" "}
            <button
              className="px-3 bg-orange-600 py-2  rounded-xl text-white"
              onClick={handleResetAll}
            >
              <>{t("login again to solve it")}</>
            </button>
          </div>
        );
    }
  }

  return <Navigate to="/login" />;
}
