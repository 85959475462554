import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";
import { ROUTES } from "constants/routes";
import { getInitialFormData } from "helpers/forms";
import { loginFormData, loginSchema } from "helpers/forms/login";
import jwt_decode from "jwt-decode";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { roleService } from "services";
import { VendorLogo, VendorLogoOrange } from "../../assets/VIP-ICON-SVG";
import { MainButton } from "../../components/Buttons";
import { MainInput } from "../../components/Inputs";
import { switchLang } from "../../helpers/lang";
import toastPopup from "../../helpers/toastPopup";
import { authActions } from "../../store/auth-slice";
import "./Login.scss";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [userType, setUserType] = useState("vendor");
  const [user, setUser] = useState(getInitialFormData(loginFormData));

  function handleLoginCatch(e) {
    setLoading(false);
    setErrorList([e.response?.data?.error]);
  }

  function handleLoginSuccess(data) {
    setLoading(false);
    toastPopup.success(t("Success"));
    const tokenDecoded = jwt_decode<any>(data.token);
    dispatch(
      authActions.login({
        token: data.token,
        vendorId:
          tokenDecoded.role === "vendor"
            ? tokenDecoded._id
            : tokenDecoded.vendor,
        branchId: tokenDecoded.role === "branch" && tokenDecoded._id,
        userRole: tokenDecoded.role,
        userData: data.record,
      })
    );
    navigate("/");
  }

  const loginHandler = async (e) => {
    e.preventDefault();
    setErrorList([]);
    const { error, value } = loginSchema.validate(user);
    setLoading(true);

    if (!error) {
      return roleService(userType)
        .login(value)
        .then((data) => {
          handleLoginSuccess(data);
        })
        .catch(handleLoginCatch);
    }

    setLoading(false);
    setErrorList(error.details.map((e) => e.message));
  };

  return (
    <div className="login">
      <div className="login-logo">
        <VendorLogo className="admin-login-logo" />
      </div>

      <div className="login-form">
        <div className="app-logo-small">
          <VendorLogoOrange className="login-logo-small" />
        </div>
        <ToggleLangBtn />

        <form
          className="login-box app-card-shadow max-xs:!w-full max-xs:!shadow-none"
          onSubmit={loginHandler}
          noValidate
        >
          <p>{t("login")}</p>

          {/* select role */}
          <div className="login-user-type">
            <p className="login-as">{t("loginAs")}</p>

            <div className="main-input-select">
              <div className="check-field">
                <div className="form-check">
                  <label className="form-check-label" htmlFor="userTypeVendor">
                    <input
                      hidden
                      type="radio"
                      name="userType"
                      id="userTypeVendor"
                      className="form-check-input"
                      checked={userType === "vendor"}
                      onChange={() => setUserType("vendor")}
                    />
                    <span className="form-check-circle"></span>
                    {t("vendor")}
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label" htmlFor="userTypeBranch">
                    <input
                      hidden
                      type="radio"
                      name="userType"
                      id="userTypeBranch"
                      className="form-check-input"
                      checked={userType === "branch"}
                      onChange={() => setUserType("branch")}
                    />
                    <span className="form-check-circle"></span>
                    {t("branch")}
                  </label>
                </div>

                <div className="form-check">
                  <label className="form-check-label" htmlFor="userTypecashier">
                    <input
                      hidden
                      type="radio"
                      name="userType"
                      id="userTypecashier"
                      className="form-check-input"
                      checked={userType === "cashier"}
                      onChange={() => setUserType("cashier")}
                    />
                    <span className="form-check-circle"></span>
                    {t("cashier")}
                  </label>
                </div>
              </div>
            </div>
          </div>

          {loginFormData.map((formInput, index) => {
            return (
              <MainInput
                {...formInput}
                key={formInput.name}
                state={user}
                setState={setUser}
              />
            );
          })}
          <FormErrorMessage errorList={errorList} />
          <MainButton type="submit" text={t("login")} loading={loading} />
          <div className="text-primary hover:opacity-80 capitalize">
            <Link to={`/${ROUTES.FORGOT_PASSWORD}`}>{t("forgotPassword")}</Link>
          </div>

          {/*---------FOOTER---------*/}

          <p className="login-footer flex flex-row gap-3 flex-wrap justify-center">
            <span className="whitespace-nowrap">{t("notRegistered")}</span>

            <Link to="/register/create" className="link-item whitespace-nowrap">
              {t("CreateAccount")}
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
}

const ToggleLangBtn = () => {
  const { i18n } = useTranslation();
  function changeLang(lang) {
    i18n.changeLanguage(lang);
    switchLang(lang);
  }
  return (
    <div className="lang">
      {localStorage.getItem("i18nextLng") === "en" ? (
        <button onClick={() => changeLang("ar")}>العربية</button>
      ) : (
        <button onClick={() => changeLang("en")}>English</button>
      )}
    </div>
  );
};
