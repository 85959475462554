import {
  emailSchema,
  passwordSchema,
  phoneSchema,
} from "helpers/validations/common";
import { t } from "i18next";
import Joi from "joi";

export const registerSchema = Joi.object({
  name_en: Joi.string()
    .messages({
      "any.required": t("nameValidation"),
      "string.empty": t("nameValidation"),
    })
    .trim()
    .required(),
  name_ar: Joi.string()
    .messages({
      "any.required": t("nameValidation"),
      "string.empty": t("nameValidation"),
    })
    .trim()
    .required(),
  email: emailSchema,
  phone: phoneSchema,
  password: passwordSchema,
  "re-password": Joi.any()
    .equal(Joi.ref("password"))
    .required()
    .messages({
      "any.required": t("rePasswordValidation"),
      "any.only": t("rePasswordValidation"),
    }),
  description_en: Joi.string()
    .messages({
      "any.required": t("descriptionValidation"),
      "string.empty": t("descriptionValidation"),
    })
    .trim()
    .required(),
  description_ar: Joi.string()
    .messages({
      "any.required": t("descriptionValidation"),
      "string.empty": t("descriptionValidation"),
    })
    .trim()
    .required(),
})
  .with("password", "re-password")
  .messages({
    "object.with": t("rePasswordValidation"),
  });

export const registerFormData = [
  { name: "name_en", type: "text", required: true },
  { name: "name_ar", type: "text", required: true },
  { name: "email", type: "email", required: true },
  { name: "password", type: "password", required: true },
  { name: "re-password", type: "password", required: true },
  { name: "phone", type: "tel", required: true },
  { name: "description_en", type: "textarea", required: true },
  { name: "description_ar", type: "textarea", required: true },
];
