export const getLocalStorageKey = function (key) {
  try {
    const storeKey = localStorage.getItem(key);

    try {
      return JSON.parse(storeKey);
    } catch (jsonError) {
      // Handle non-JSON strings here
      return storeKey;
    }
  } catch (e) {
    localStorage.removeItem(key);
    return null;
  }
};
