import PageLoader from "components/PageLoader/PageLoader";
import { ROUTES } from "constants/routes";
import ForgetPassword from "pages/Login/ForgotPassword";
import Register from "pages/Register/Register";
import RegisterForm from "pages/RegisterForm/RegisterForm";
import RegisterHome from "pages/RegisterHome/RegisterHome";
import { Suspense, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router";
import { SocketProvider } from "services/socket/provider";
import { selectAuth } from "store/auth-slice";
import { checkFixLang } from "./helpers/lang";
import i18n from "./locales/i18n";
import Login from "./pages/Login/Login";
import ProtectedRoute from "./routes/ProtectedRoute/ProtectedRoute";

function App() {
  const lang = i18n.language as "en" | "ar";
  const auth = useSelector(selectAuth);

  useLayoutEffect(() => {
    checkFixLang(lang);
  }, [lang]);

  return (
    <div className="App">
      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route
            path="/*"
            element={
              <SocketProvider>
                <ProtectedRoute />
              </SocketProvider>
            }
          />
          <Route
            path="/login"
            element={auth.token ? <Navigate to="/" /> : <Login />}
          />
          <Route
            path={ROUTES.FORGOT_PASSWORD}
            element={auth.token ? <Navigate to="/" /> : <ForgetPassword />}
          />
          <Route
            path="/register"
            element={auth.token ? <Navigate to="/" /> : <Register />}
          >
            <Route index element={<RegisterHome />} />
            <Route path="create" element={<RegisterForm />} />
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
