import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { MainButton } from "components/Buttons";
import { MainInput } from "components/Inputs";
import { useNavigate } from "react-router";
import vendorServices from "services/vendorServices";
import toastPopup from "helpers/toastPopup";
import FormErrorMessage from "components/FormErrorMessage/FormErrorMessage";
import { clearEmpty } from "helpers/clear-empty";
import { registerFormData, registerSchema } from "helpers/forms/register";
import { getInitialFormData } from "helpers/forms";

export default function RegisterForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [errorList, setErrorList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(getInitialFormData(registerFormData));

  let timer: NodeJS.Timer;
  async function registerHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setErrorList([]);

    const filteredObj = clearEmpty(user);
    const { value: _user, error } = registerSchema.validate(filteredObj);

    setLoading(true);
    if (error) {
      setErrorList(error.details.map((e) => e.message));

      setLoading(false);
      return 1;
    }
    const mappedData = {
      ..._user,
      name: {
        en: _user.name_en,
        ar: _user.name_ar,
      },
      description: {
        en: _user.description_en,
        ar: _user.description_ar,
      },
      type: "vendor",
    };
    //! could be handled with destrcuturing
    delete mappedData.name_en;
    delete mappedData.name_ar;
    delete mappedData.description_en;
    delete mappedData.description_ar;
    delete mappedData["re-password"];
    setLoading(true);

    try {
      const { data } = await vendorServices.register(mappedData);
      if (data.success && data.code === 201) {
        setLoading(false);
        toastPopup.success(t("Success"));
        navigate("/login");
      }
    } catch (e) {
      if (e.response.data.code === 409) {
        toast.error(e.response.data.error);
        timer = setTimeout(() => {
          navigate("/login");
        }, 3000);
      }
      setLoading(false);
      setErrorList([e.response.data.error]);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <form
      className="flex flex-col w-full justify-center items-center mx-auto gap-4"
      onSubmit={registerHandler}
      noValidate
    >
      {registerFormData.map((formInput, index) => {
        return (
          <MainInput
            key={formInput.name}
            {...formInput}
            required={formInput.required}
            state={user}
            setState={setUser}
          />
        );
      })}

      <FormErrorMessage errorList={errorList} />
      <MainButton
        active={true}
        text={t("register")}
        loading={loading}
        variant="primary"
        type="submit"
      />
    </form>
  );
}
