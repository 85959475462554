import Joi from "joi";

const schema = {
  createProduct: Joi.object().keys({
    name: Joi.object().keys({
      en: Joi.string().required(),
      ar: Joi.string().required(),
    }),
    branches: Joi.array()
      .items(
        Joi.object().keys({
          _id: Joi.string().required(),
          name: Joi.object().keys({
            en: Joi.string().required(),
            ar: Joi.string().required(),
          }),
        })
      )
      .required(),
    vendor: Joi.string(),
    category: Joi.string(),
    originalPrice: Joi.number().positive().required(),

    price: Joi.number().positive().max(Joi.ref("originalPrice")).messages({
      "number.max": "currentPriceLowerThanOriginal",
    }),

    description: Joi.object().keys({
      en: Joi.string().required(),
      ar: Joi.string().required(),
    }),
    isHotDeal: Joi.boolean(),
  }),
  createAd: Joi.object().keys({
    name: Joi.string().required(),
    sponsor: Joi.string().required(),
    category: Joi.string().required(),
    position: Joi.string().required(),
    dailyBudget: Joi.number(),
    startDate: Joi.date(),
    endDate: Joi.date(),
  }),
};
export default schema;
