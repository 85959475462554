function PageLoader() {
  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <div
        className="inline-block h-28 w-28 animate-spin rounded-full border-8 border-solid border-primary/80 border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
    </div>
  );
}

export default PageLoader;
